import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import Cta from "../../components/Cta";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import ContentSlider from "../../components/GSTTemp/ContentSlider";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import Package from "../../components/GSTTemp/Package";

export default function ShopEstablishmentLicense() {
  /* Slider */
  // const ShopSlider = [
  //   {
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Shop & Establishment License Registration`,
  //     content: `Apply Online Shop and Establishment License with TODAYFILINGS Experts`,
  //     image: "/imgs/registration/shop/shop-est-benefits.png",
  //     alt_tag: "Best Online Shop and Establishment License Service in Hosur",
  //   },
  //   {
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Best Shop & Establishment License in Hosur`,
  //     content: `Top most popular online shop establishment registration service in Hosur `,
  //     image: "/imgs/registration/shop/shop-slide.png",
  //     alt_tag: "Online Shop and Establishment License in Hosur",
  //   },
  //   {
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Shop & Establishment License Registration Service`,
  //     content: `Best Online Shop and Establishment License Service in Hosur`,
  //     image: "/imgs/registration/shop/shop-img-slider.png",
  //     alt_tag: "Best Online Shop and Establishment License near Bangalore",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Shop Establishment License`,
    buyBtnLink: `#pricing-buy`,
    price: `4999`,
    f_price: `6999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/gst.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/itr_filing.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `MSME Registration`,
        icon: '/imgs/icons/enterprises.png',
        alt_tag: 'MSME Registration',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Tax Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };

  /* About Data With Form*/
  const ShopAboutData = {
    header: `Shop And Establishment License Online`,
    sub_title: `SHOP AND ESTABLISHMENT LICENSE ONLINE IN INDIA`,
    content_paragraph: [
      // `The Shop and Establishment Act Registration License aims to safeguard
      //  stationary benefits for workers who are employed by enterprises like stores and commercial establishments like lodging
      //  facilities, theatres, and dining establishments.`,

      `When opening a store, hotel, theatre, place of public entertainment, or any other type of commercial establishment in India,
       you must obtain a Shop and Establishment License online. The licenses are important for shop and establishment registration.
       Documents required for the Incorporation Certificate. Registration under the Shop and Establishment Act.`,
    ],
  };

  /* Scroll Nav Data */
  const ShopScrollId = [
    {
      id: `#shop-procedure`,
      heading: `Registration Procedure`,
    },
    {
      id: `#documents`,
      heading: `Required Documents`,
    },
    {
      id: `#important`,
      heading: `Shop Establishment`,
    },
    {
      id: `#benefits`,
      heading: `Benefits`,
    },
  ];

  /* Img Content Component Data */
  const ShopIcData = {
    id: 'shop-procedure',
    mt_div: 'mt-5',
    mt_img: '',
    header:
      'Registration Procedure To Obtain Shop And Establishment License In India',
    image: '/imgs/registration/shop/shop-reg.png',
    alt_tag: 'Shop and establishment license',
    points: [
      {
        head: '',
        content: `The Shop and Establishment Act's rules are followed by the majority of companies
         in India, including eateries, shops, cafes, and so on.`,
        icon: true,
      },
      {
        head: '',
        content: `Within 30 days of the formation of business activities, any commercial shop, establishment,
        or company must submit an online application for a licence under the Shop Act to the chief inspector
        of that specific site`,
        icon: true,
      },
      {
        head: '',
        content: `The first step is to download the application from the state's official website, depending on where
        your shop or establishment will be. `,
        icon: true,
      },
      {
        head: '',
        content: `Include all necessary information, including the business name, applicant's name and contact
        information, employee information, the address of the registered business, a rent agreement if the space is
        rented, and the applicant's PAN.`,
        icon: true,
      },
      {
        head: '',
        content: `Submit the application to the chief inspector in charge of the shop establishment license after
        filling out all the necessary information and paying the required costs.`,
        icon: true,
      },
      {
        head: '',
        content: `The establishment receives its licence after the authorized inspector has approved the application.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const ShopCfData = {
    id: 'documents',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header:
      'Documents for obtaining the Shop and Establishment License in India',
    content: ``,
    body: [
      {
        head: '',
        points: `Any official form of identification for the applicant with utility bill for a business or other
        establishment`,
        icon: true,
      },
      {
        head: '',
        points: `Picture of the
        applicant or owner and the store or other establishment.`,
        icon: true,
      },
      {
        head: '',
        points: `Duplicate of the
        lease if the place of business is rented property.`,
        icon: true,
      },

      {
        head: 'Additional paperwork required according to the business entity or setup',
        points: ``,
        icon: false,
      },
      {
        head: '',
        points: `ROC and Memorandum of Association(MoA) of the establishment`,
        icon: true,
      },
      {
        head: '',
        points: `In the case of a cooperative society, a complete list of the chairman and members is necessary.`,
        icon: true,
      },
      {
        head: '',
        points: `A partnership
        agreement that includes all necessary information, including
        the necessary details of the partners.`,
        icon: true,
      },
      {
        head: '',
        points: `FSSAI License for
        food and drugs and RBI authorization copy.`,
        icon: true,
      },
      {
        head: '',
        points: `Companies Act
        certificate of incorporation,and Copy of addressing the
        start of society's business.`,
        icon: true,
      },
      {
        head: '',
        points: `Certificate of
        charity commissioner-issued registration and Copy with
        permission from RTO Transportation.`,
        icon: true,
      },
      {
        head: '',
        points: `An IEC
        certificate, a NOC from the police department for a
        Cybercafe, and a copy of the Collector's approval are all
        required.`,
        icon: true,
      },
      {
        head: '',
        points: `NOC obtained by
        the local corporation for opening a masala and flour mill.`,
        icon: true,
      },
      {
        head: '',
        points: `If it is a trust,
        a list of number of trustees and directors with any certificate
        issued to the share broker by SEBI.`,
        icon: true,
      },
      {
        head: '',
        points: `Start a
        restaurant, beer bar, or wine shop without an excise
        license.`,
        icon: true,
      },
      {
        head: '',
        points: `The authorization
        issued by the police department to begin providing security
        services.`,
        icon: true,
      },
    ],
  };

  /* ImgSlider Data */
  const ShopEstablishmentImages = [
    '/imgs/registration/shop/shop-est-img.png',
    '/imgs/registration/shop/shop-img-slide.png',
    '/imgs/registration/shop/shop-imgslider.png',
  ];

  /* ContentSlider Component Data */
  const ShopSCData = {
    id: 'important',
    background: [],
    mt_div: 'mt-3',
    header: 'Who Should Obtain A Shop And Establishment License In India?',
    content: [
      {
        points: `The retailers or Wholesalers and Service Centers`,
        icon: true,
      },
      {
        points: `Store-rooms, Warehouses, and Godowns owners`,
        icon: true,
      },
      {
        points: `Hotels, eateries and food restaurants`,
        icon: true,
      },
      {
        points: `Additionally, any other working places`,
        icon: true,
      },
      {
        points: `Amusement parks, Entertainment houses, Theaters, etc.,`,
        icon: true,
      },
    ],
    images: ShopEstablishmentImages,
  };

  /* Img Content Component Data */
  const ShopsIcData = {
    id: 'benefits',
    background: 'main-content',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'Benefits Of Getting Shop And Establishment License In India',
    image: '/imgs/registration/shop/shop-slider.png',
    alt_tag: 'benefits of shop establishment',
    points: [
      {
        content: `This fundamental license aids businesses in obtaining other legal registrations.`,
        icon: true,
      },
      {
        content: `Numerous business-related activities, such as opening a bank account in the name of a store
        or other establishment, require registration.`,
        icon: true,
      },
      {
        content: `Formal or legal recognition for that specific place of business, shop, or establishment.`,
        icon: true,
      },
      {
        content: `Serves as business documentation while requesting additional Indian legal registrations.`,
        icon: true,
      },
      {
        content: `Aids in attracting investments, loans, or venture capital for a business or store.`,
        icon: true,
      },
    ],
  };

  var ShopEstablishmentFAQ = [
    {
      header: 'When is a Shop and Establishment Act Registration needed?',
      body: [
        {
          content: ` All stores and establishments must register under this Act, and they
          must do so within a month or 30 days of opening. Since it serves as
          a foundational license, it is required for any other operations,
          such as opening a bank account in the business' name.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: `What list of records should be maintained as per the Shop and
      Establishment Act?`,
      body: [
        {
          content: `Holidays awarded to the workers`,
          icon: true,
        },
        {
          content: `Any fine applied`,
          icon: true,
        },
        {
          content: `Advances details`,
          icon: true,
        },
        {
          content: `Salary details`,
          icon: true,
        },
        {
          content: `A register containing
          all details of employment`,
          icon: true,
        },
        {
          content: `Any deduction from
          salary`,
          icon: true,
        },
      ],
    },
    {
      header: 'Is it necessary to take approval from the labor department?',
      body: [
        {
          content: `Yes, every establishment must receive approval from the Department
          of Labor before opening for business under the Shop and
          Establishment Act.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How is term shop is defined under shop and establishment act?',
      body: [
        {
          content: `“Shop” under this act is defined as any premise where goods are sold in wholesale or retail.
          It also includes place where services are rendered, warehouse, Godown, workhouse etc. It excludes factory
          as same is covered under Factories act 1948.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is Shop Act registration and MSME the same?',
      body: [
        {
          content: `The Ministry of Micro, Small and Medium Enterprises provides Udyog Aadhaar, and the city's municipality offers shop & establishment certificates.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What aspects does the Shop and Establishment Act regulate?',
      body: [
        {
          content: `Most businesses and their workforce in India come under the SEA. This Act regulates several aspects of work-life, including earned leaves, child labour, wages, working hours, holidays, women's rights, etc.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Why do all establishments need shop and establishment registration?',
      body: [
        {
          content: `These establishments include commercial spaces, theatres, residential hotels, restaurants or other places of public entertainment.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is Udhyog Aadhar different from the Shop Act License?',
      body: [
        {
          content: `The Udyog Aadhaar Memorandum is applied under MSMED Act guidelines to recognise MSME and statutory formation. At the same time, State Shop Establishment Act certification specifies general duties, rules and regulations of all business shops/establishments toward their employees.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: `When and where does the employee related information should be
        submitted as per the act?`,
      body: [
        {
          content: ` Documents pertaining to annual holidays and the number of employees
          must be provided yearly to the Municipal Corporation office.`,
          icon: false,
        },
      ],
    },
    {
      header: `How is term "establishment" is defined under shop and establishment act?`,
      body: [
        {
          content: `establishment could be anything from Shop, commercial establishment, Residential hotel,
          Restaurant, Theatre, public amusement, Retail trade or business.`,
          icon: false,
        },
      ],
    },
  ];

  return (
    <div>
      <Seo
        title='Shop Establishment License'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />

      <Layout>
        <main id='main'>
          {/* <Hero sliders={ShopSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={ShopAboutData} />

          <Counter />

          <ScrollNav scroll_data={ShopScrollId} />

          <ImgContent item={ShopIcData} />

          <ContentForm CFSection_data={ShopCfData} />

          <ContentSlider CsSection_data={ShopSCData} />

          <ImgContent item={ShopsIcData} />

          <Cta />
          <Guidance />

          <FAQAccord items={ShopEstablishmentFAQ} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
